import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import '../../CSS/FAQ.css'

export default function FAQMobile() {
    var accordionBody = [
        {
            'domanda':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Come guadagna Crowd<span className='text-orange'>Moovi</span>?
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Crowd<span className='text-orange'>Moovi</span> guadagna principalmente grazie alle commissioni pagate degli artisti e alla commissione del 2% 
                    applicata alla compravendita degli NFT. 

                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Quali tipologie di progetti posso finanziare su Crowd<span className='text-orange'>Moovi</span>?
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Se sei un’artista siamo aperti a tutti i progetti in ambito cinematografico,
                     che siano film, cortometraggi, lungometraggi o serie tv. 
                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Da quando potrò usufruire dei vantaggi del mio NFT?
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Parlando degli NFT della collezione “Genesis NFT” i vantaggi saranno riscattabili al momento
                     del lancio della versione alfa del marketplace,
                     previsto per la metà del 2023.

                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Posso acquistare un <span className='text-orange'>Genesis</span> NFT anche senza un wallet crypto? 
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    Si, acquistando con la carta di credito o debito ti viene generato un wallet in automatico dove andrà a finire il tuo NFT
                </Typography>
        },
    ]
    return (
        <Grid container style={{marginBottom:100}} className={'mt-28'} sx={{ pl: { xs: 0, md: 14 } }}>
            <Grid item xs={12} className={'text-center-browser'}>
                <Typography component={'div'} className={'text-orange !text-5xl font-mont !mb-10'}>
                    FAQ
                </Typography>
                {accordionBody.map((accordion, index) => {
                    return (
                        <Accordion className={'border-accordion'} key={index} sx={{ width: { xs: 350, md: 650 } }}>
                            <AccordionSummary
                                aria-controls="panel-content"
                                id="panel-header"
                                className='!justify-center'
                            >
                                {accordion.domanda}
                            </AccordionSummary>
                            <AccordionDetails>
                                {accordion.risposta}
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Grid>
        </Grid>
    );
}