import { Box, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import styles from '../CSS/PercheCrowdmoovi.module.css'

function PercheCrowdmoovi() {

    var accordionBody = [
        {
            'domanda':
                <Typography component={'div'} className={'text-orange !text-4xl !font-bold font-garet'}>
                    RISPARMIO PER GLI ARTISTI
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    SI APPLICA UNA COMMISSIONE SUI FONDI RACCOLTI SOLAMENTE SE IL PROGETTO RAGGIUNGE L'OBIETTIVO DI FINANZIAMENTO.
                    <br />
                    <br />
                    QUESTA COMMISSIONE (5-8% SU PIATTAFORME ESTERNE), SU CROWDMOOVI PARTE DA UN 3% PER ARRIVARE ALL'1% SE SI POSSIEDE UN "GENESIS NFT".
                    <Box component={'img'} src='/Images/graficoATorta.png' />
                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-orange !text-4xl !font-bold font-garet'}>
                    ROYALTIES E COMMUNITY
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    CREA LA TUA CAMPAGNA E GUADAGNA ROYALTIES FINO AL <span className='text-orange'>10%</span> SU OGNI RIVENDITA DELLE NFT CARDS CREATE DA TE.
                    <br />
                    <br />
                    UTILIZZA LE TUE CARDS PER OFFRIRE IMPERDIBILI VANTAGGI E CREARE UNA SOLIDA <span className='text-orange'>COMMUNITY</span> DI PERSONE APPASSIONATE AL PROGETTO.

                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-violet !text-4xl !font-bold font-garet'}>
                    PIU' RICOMPENSE PER I SOSTENITORI
                </Typography>,
            'risposta':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    GRAZIE ALLE NFT CARDS GLI ARTISTI POSSONO RICOMPENSARE I SOSTENITORI CON UN' INFINITA' DI VANTAGGI FISICI E/O DIGITALI.
                    <br />
                    <br />
                    UNA CARD RAPPRESENTA UNA <span className='text-orange'>FRAZIONE DEL DIRITTO D'AUTORE DEL FILM</span>, OLTRE AI VANTAGGI GIÀ VISIONATI, E PUÒ ESSERE SCAMBIATA O RIVENDUTA, A DIFFERENZA DEI VANTAGGI DELLE ALTRE PIATTAFORME DI CROWDFUNDING.
                </Typography>
        }
    ]

    return (
        <Grid container className='h-full w-full container_grid'>
            {/* ----- TITOLO ------- */}
            <Grid item xs={12} className={'item_grid'}>
                <Typography component={'div'} className={'!text-6xl text-violet italic text-center pt-14 block font-mont ' + styles.center_abs_text}>
                    COME FUNZIONA
                    <br />
                    CROWD<span className='text-orange inline-flex'>MOOVI?</span>
                </Typography>
            </Grid>
            {/* PRIMA DESCRIZIONE INIZIO*/}
            <Grid item xs={8} lg={10} className={'text-center'}>
                <Grid container>
                    <Grid item xs={1} >
                        <Box
                            className={styles.cinepresa1}
                            component={'img'}
                            src='/Images/cinepresa1.png'
                            sx={{
                                height: 200,
                                width: 400,
                                maxHeight: { xs: 330, md: 330 },
                                maxWidth: { xs: 550, md: 550 },
                            }}
                        />
                    </Grid>
                    <Grid item xs={11} >
                        <Typography style={{ textAlign: 'left', marginLeft: "130px", marginBottom: '-50px' }} component={'div'} className={'!text-4xl text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                            <span className='text-orange inline-flex'>CREA</span>
                            <br />
                            <span className='text-orange inline-flex'>IL TUO </span>
                            &nbsp;PROFILO
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component={'div'} className={'!text-4xl text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                            <span className='text-orange inline-flex'>E LA TUA CAMPAGNA</span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={8} >
                    <Typography component={'h4'} className={' text-violet text-center pt-5 block font-mont !pr-20 '  + styles.line_h_22} sx={{ px: { xs: 4, md: 0 } }}>
                        &nbsp; &nbsp; <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span> INSERISCI I TUOI DATI, REGISTRATI E VAI ALLA SEZIONE “LE MIE CAMPAGNE”
                        <br />
                    </Typography>
                    <Typography component={'h4'} style={{ marginTop: 5 }} className={' text-violet  text-center pt-2 block font-mont !pr-20 '  + styles.line_h_22}  sx={{ px: { xs: 4, md: 0 } }}>
                        <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span> DUNQUE SERVIRA' SOLO INSERIRE LE INFORMAZIONI RILEVANTI RIGUARDO AL PROGETTO
                    </Typography>
                </Grid>
            </Grid>
            <Grid item lg={2}>
                <Box
                    component={'img'}
                    src='/Images/grafica_1.png'
                    sx={{
                        position: 'absolute',
                        top: 160,
                        width: 615,
                        right: 0
                    }}
                />
            </Grid>
            {/* PRIMA DESCRIZIONE FINE*/}
            {/* SECONDA DESCRIZIONE INIZIO*/}
            <Grid item xs={12} lg={12} style={{ marginTop: 100 }} className={'text-center'}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box
                            component={'img'}
                            src='/Images/grafica_2.png'
                            sx={{
                                position: 'absolute',
                                width: 500,
                                marginTop: -6,
                                marginLeft: 11
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className='inline-flex' >
                        <Box
                            className={styles.clapperBoard1}
                            component={'img'}
                            src='/Images/clapperboard1.png'
                            sx={{
                                height: 130,
                                width: 270,
                                maxHeight: { xs: 250, md: 250 },
                                maxWidth: { xs: 450, md: 450 },
                            }}
                        />
                        <Typography component={'div'} className={'!text-4xl text-violet block font-mont !-ml-36 ' + styles.center_abs_text}>
                            <span className='text-orange inline-flex'>CREA LE TUE</span> NFT CARDS
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} />
                    <Grid item xs={6} >
                        <Typography component={'h4'} style={{ marginTop: -30}} className={' text-violet  text-center pt-5 block font-mont !inline-flex ' + styles.line_h_22} sx={{ px: { xs: 4, md: 2 } }}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span>  <span> STABILISCI PREZZO, ROYALTIES ED ASSOCIA I VANTAGGI ALLE CARDS, TRA CUI LA <span className='font-garet'>% </span> 
                            SUL DIRITTO D'AUTORE SULL'OPERA E ULTERIORI VANTAGGI FISICI<span className='font-garet'>/</span>DIGITALI </span>
                        </Typography>
                        <Typography component={'h4'} style={{ marginTop: 5 }} className={' text-violet  text-center pt-5 block font-mont !inline-flex '  + styles.line_h_22} sx={{ px: { xs: 4, md: 2 } }}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span>  &nbsp; &nbsp; &nbsp; &nbsp; CREA IL DESIGN DELLE NFT CARDS O AGGIUNGILO IN UN SECONDO MOMENTO
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* SECONDA DESCRIZIONE FINE */}
            {/* TERZA DESCRIZIONE INIZIO */}
            <Grid item xs={8} lg={10} className={'text-center'}>
                <Grid container>
                    <Grid item xs={1} >
                        <Box
                            className={styles.cinepresa3}
                            component={'img'}
                            src='/Images/cinepresa3.png'
                            sx={{
                                height: 200,
                                width: 400,
                                maxHeight: { xs: 330, md: 330 },
                                maxWidth: { xs: 550, md: 550 },
                            }}
                        />
                    </Grid>
                    <Grid item xs={11} >
                        <Typography style={{ textAlign: 'left', marginLeft: "130px", marginBottom: '-50px' }} component={'div'} className={'!text-4xl text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                            <span className='text-orange inline-flex'>LANCIA PUBBLICAMENTE </span>
                            <br />
                            <span className='text-orange inline-flex'>LA TUA </span> CAMPAGNA
                        </Typography>
                        <Typography style={{ textAlign: 'left' }} component={'div'} className={'!text-4xl text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                            <span className='text-orange inline-flex'>E LE TUE </span> NFT CARDS
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={8} >
                    <Typography component={'h4'} className={' text-violet  text-center pt-5 block font-mont !pr-20 !inline-flex ' + styles.line_h_22} sx={{ px: { xs: 4, md: 0 } }}>
                    <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span> <span style={{marginLeft: 30}}>&nbsp;&nbsp; SELEZIONA LA DURATA DI 30 O 60 GIORNI E LA TIPOLOGIA DI CAMPAGNA CHE PIU' SI ADATTA ALLE TUE ESIGENZE TRA: </span>
                    </Typography>
                    <Typography component={'h4'} style={{ marginTop: 5 }} className={' text-violet italic text-center pt-3 block font-mont !pr-20 !inline-flex ' + styles.line_h_22} sx={{ px: { xs: 4, md: 0 } }}>
                    <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span> <span> &nbsp; &nbsp; &nbsp; "TUTTO O NIENTE": SE IL PROGETTO NON RAGGIUNGE L'OBIETTIVO PREFISSATO VENDENDO TUTTI GLI NFT, I SOSTENITORI DEL PROGETTO VENGONO RIMBORSATI</span>
                    </Typography>
                    <Typography component={'h4'} style={{ marginTop: 5 }} className={' text-violet italic text-center pt-3 block font-mont !pr-20 !inline-flex ' + styles.line_h_22} sx={{ px: { xs: 4, md: 0 } }}>
                    <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span> <span> &nbsp; &nbsp; &nbsp; "FLESSIBILE": SE IL PROGETTO RAGGIUNGE ALMENO IL 60<span className='font-garet !inline-flex'>%</span> DELL'OBIETTIVO PREFISSATO SI PROCEDE COMUNQUE CON IL SUO SVILUPPO</span>
                    </Typography>
                </Grid>
            </Grid>
            <Grid item lg={2}>
                <Box
                    component={'img'}
                    src='/Images/grafica_3.png'
                    sx={{
                        position: 'absolute',
                        marginTop: 10,
                        marginRight: 4,
                        width: 615,
                        right: 0
                    }}
                />
            </Grid>
            {/* TERZA DESCRIZIONE FINE */}
            {/* QUARTA DESCRIZIONE INIZIO */}
            <Grid item xs={12} lg={12} style={{ marginTop: 100 }} className={'text-center'}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box
                            component={'img'}
                            src='/Images/grafica_4.png'
                            sx={{
                                position: 'absolute',
                                width: 595,
                                marginTop: -9,
                                marginLeft: 10
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className='inline-flex'>
                        <Box
                            className={styles.clapperBoard1}
                            component={'img'}
                            src='/Images/clapperboard4.png'
                            sx={{
                                height: 130,
                                width: 270,
                                maxHeight: { xs: 250, md: 250 },
                                maxWidth: { xs: 450, md: 450 },
                            }}
                        />
                        <Typography component={'div'} className={'!text-4xl text-violet block font-mont !-ml-14 !text-left ' + styles.center_abs_text}>
                            VENDI <span className='text-orange inline-flex'>LE CARDS</span>
                            <br />
                            <span className='text-orange inline-flex'>E</span> FINANZIA <span className='text-orange inline-flex'>IL PROGETTO</span>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} />
                    <Grid item xs={6} >
                        <Typography component={'h4'} style={{ marginTop: -20 }} className={styles.line_h_22 + ' text-violet  text-center pt-5 block font-mont !inline-flex'} sx={{ px: { xs: 4, md: 2 } }}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span> AL TERMINE DELLA CAMPAGNA, SE QUEST'ULTIMA HA SUCCESSO I FONDI VENGONO INVIATI AL TEAM ARTISTICO, IN CASO CONTRARIO GLI INVESTITORI VENGONO RIMBORSATI </span>
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
            {/* QUARTA DESCRIZIONE FINE */}

            <Grid item xs={12} style={{ marginTop: 150 }} className={'item_grid'}>
                <Typography component={'div'} className={'!text-7xl text-violet italic text-center block font-mont ' + styles.center_abs_text}>
                    PERCHE' SCEGLIERE
                    <br />
                    CROWD<span className='text-orange inline-flex'>MOOVI?</span>
                </Typography>
            </Grid>
            <Grid style={{ marginTop: 150 }} spacing={0} container className={'mt-10 !mb-10 mx-20'}>
                {accordionBody.map((accordion, index) => {
                    return (
                        <Grid item xs={4} className={'text-center-browser'} key={index}>
                            <Accordion className={'border-accordion !rounded-none'} key={index}>
                                <AccordionSummary
                                    aria-controls="panel-content"
                                    id="panel-header"
                                    className='!justify-center'
                                >
                                    {accordion.domanda}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {accordion.risposta}
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    );
}

export default PercheCrowdmoovi;
