import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import styles from '../../CSS/Descrizione.module.css'

function DescrizioneMobile(props) {
    const [userHasScrolled, setUserHasScrolled] = useState(false);

    document.body.onscroll = function () {
        setUserHasScrolled(true)
    }
    return (
        <Grid container className={'bg-violet '} sx={{ pt: { xs: 0, md: 0 } }}>
            {/* ------------- PRIMO GRID 70% PAGINA ------- */}
            <Grid container style={{ textAlign: 'center', marginTop: 60 }} sx={{ pl: { xs: 2, md: 14 }, pr: { xs: 2, md: 0 } }}>
                <Grid item style={{ textAlign: 'center' }} xs={12} md={6} sx={{ pb: { xs: 2, md: 6 } }}>
                    <Grid container style={{ textAlign: 'center' }} sx={{ pl: { xs: 2, md: 14 }, pr: { xs: 2, md: 0 } }}>
                        <Grid item style={{ textAlign: 'center' }} xs={12} md={6} sx={{ pb: { xs: 2, md: 6 } }}>
                            <Typography component={'h2'} style={{ textAlign: 'center' }} className={styles.mt_10p + ' text-white italic font-mont'} sx={{ fontSize: { xs: 30, md: 80 } }}>
                                <span>NFT REWARD</span>
                            </Typography>
                            <Typography component={'h2'} style={{ textAlign: 'center', marginTop: -20 }} className={styles.mt_10p + ' text-white italic font-mont inline-flex'} sx={{ fontSize: { xs: 30, md: 80 } }}>
                                CROWD<span className='text-orange inline-flex'>FUNDING</span>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography style={{ textAlign: 'center' }} component={'div'} className={styles.mt_10p + ' text-white font-garet'} sx={{ pl: { xs: 4, md: 14 }, pr: { xs: 4, md: 0 }, width: { xs: 'initial' }, fontSize: { xs: 13, md: 80 } }}>
                                La prima piattaforma di Crowdfunding tramite
                                NFT che permette agli artisti cinematografici di
                                finanziare i propri prodotti in autonomia, con
                                innumerevoli vantaggi.
                                <Typography component={'p'} className={styles.mt_20p + ' text-center-browser font-garet !text-xl text-orange text-center pt-5'}>
                                    <Button
                                        onClick={() => props.executeScrollMobile()}
                                        className={'!text-1xl !p-3 font-mont self-center button_marketplace '}
                                        style={{ height: 40 }}
                                        sx={{ my: 2, color: 'white', display: 'block' }}
                                    >
                                        GENESIS NFT
                                    </Button>
                                </Typography>
                            </Typography>
                        </Grid>

                    </Grid>


                </Grid>
                <Grid item style={{marginTop:-15, position: 'relative', right: -16}} xs={12} md={6} sx={{ pl: { xs: 0} }} className='!self-center'>
                    <Box component={'img'} src='/Images/rullino.png' style={{maxWidth: '75%', float: 'right'}}/>
                </Grid>
            </Grid>
            <Grid item style={{ textAlign: "center" }} xs={12} className='!self-center'>
                <Typography component={'h2'} className={styles.sloganCss + ' font-mont inline-flex'} sx={{ fontSize: { xs: 40, md: 80 } }}>
                    <Grid container >
                        {/* SLOGAN MOBILE */}
                        <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
                            <span style={{ fontSize: 17.5}}>
                                <div className={styles.sloganMobile}>DAGLI ARTISTI, AGLI ARTISTI, PER L’ARTE</div> 
                            </span>
                        </Grid>
                    </Grid>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default DescrizioneMobile;
