import { SET_PAGE } from "./types";


export function setPage(page) {
    return (dispatch) => {
        dispatch({
            page: page,
            type: SET_PAGE
        });
    };
};