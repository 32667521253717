import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import SidebarMobile from '../Components/MobileComponents/SidebarMobile';
import { Grid, Link, Typography } from '@mui/material';

function Whitepaper() {
    return (
        <Grid container className='!h-screen bg-violet'>
            <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
                <SidebarMobile />
            </Grid>
            <Grid item xs={12} md={12} lg={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
                <ResponsiveAppBar />
            </Grid>
            <Grid item xs={12} md={12} lg={12} className='flex !justify-center bg-violet'>
                <Typography component={'h1'} className='text-white font-mont italic' sx={{fontSize: {xs: 45, md: 60}}}>
                    WHITEPAPER
                </Typography>
            </Grid>
            <Grid item xs={12} md={12} lg={12} className='flex !justify-center bg-violet'>
                <Link className='text-orange font-garet !text-3xl' href='/Files/Whitepaper.pdf' target='_blank' rel='noopener noreferrer'>
                    ITALIANO
                </Link>
            </Grid>
        </Grid>
    );
}

export default Whitepaper;
