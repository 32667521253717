import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import { setErrorBoolean } from '../Redux/Blockchain/BlockchainAction';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarCustom() {
  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const dispatch = useDispatch();
  const errorBoolean = useSelector(state => state.blockchain.errorBoolean);
  const errorMessage = useSelector(state => state.blockchain.errorMessage);


  const { vertical, horizontal } = state;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setErrorBoolean(false, ''));
  };

  return (
    <div>
      <Snackbar open={(errorBoolean)} onClose={handleClose} anchorOrigin={{ vertical, horizontal }} autoHideDuration={(errorBoolean) ? 4500 : null} key={vertical + horizontal}>
        <Alert onClose={handleClose} severity={'error'} action={''}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}