import '../App.css';
import PercheCrowdmoovi from '../Components/PercheCrowdmoovi';
import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import '../CSS/Application.css';
import Descrizione from '../Components/Descrizione';
import Mission from '../Components/Mission';
import MissionMobile from '../Components/MobileComponents/MissionMobile';
import Roadmap from '../Components/Roadmap';
import PossedereCrowd from '../Components/PossedereCrowd';
import MintNft from '../Components/MintNft';
import FAQ from '../Components/FAQ';
import Footer from '../Components/Footer';
import SidebarMobile from '../Components/MobileComponents/SidebarMobile';
import { CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import PercheCrowdmooviMobile from '../Components/MobileComponents/PercheCrowdmooviMobile';
import PossedereCrowdMobile from '../Components/MobileComponents/PossedereCrowdMobile';
import MintNftMobile from '../Components/MobileComponents/MintNftMobile';
import FAQMobile from '../Components/MobileComponents/FAQMobile';
import DescrizioneMobile from '../Components/MobileComponents/DescrizioneMobile';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import { setSuccess } from '../Redux/Blockchain/BlockchainAction';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Home() {
  const dispatch = useDispatch();
  var showSuspenseImg = useSelector(state => state.blockchain.showSuspenseImg);
  var success = useSelector(state => state.blockchain.success);

  const myRef = useRef(null)
  const myRefMobile = useRef(null)

  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal } = state;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(setSuccess(false));
  };

  const executeScroll = () => {
    if(myRef !== null && myRef.current !== null)
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
  const executeScrollMobile = () => {
    if(myRefMobile !== null && myRefMobile.current !== null)
      myRefMobile.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  return (
    <Grid container>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
        <SidebarMobile />
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <ResponsiveAppBar />
      </Grid>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
      <DescrizioneMobile executeScrollMobile={() => executeScrollMobile()}/>
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
      <Descrizione executeScroll={() => executeScroll()}/>
      </Grid>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
        <PercheCrowdmooviMobile />
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <PercheCrowdmoovi />
      </Grid>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
        <MissionMobile />
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <Mission />
      </Grid>
      <Roadmap />
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <PossedereCrowd executeScroll={() => executeScroll()}/>
      </Grid>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
        <PossedereCrowdMobile executeScrollMobile={() => executeScrollMobile()}/>
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
        <MintNft myRef={myRef} executeScroll={() => executeScroll()}/>
      </Grid>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
        <MintNftMobile myRefMobile={myRefMobile} executeScrollMobile={() => executeScrollMobile()}/>
      </Grid>
      <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
      <FAQ />
      </Grid>
      <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
        <FAQMobile />
      </Grid>
      <Footer />
      <Snackbar open={showSuspenseImg} anchorOrigin={{ vertical, horizontal }} style={{display: 'inline-flex'}}>
        <Alert severity="info" sx={{ width: 200 }} id='alert-info'>
          <CircularProgress color="success" style={{width: 25, height: 25, color: 'white'}}/> 
          <Typography className='font-mont !text-white !pt-1 !pl-4'>
            Waiting...
          </Typography>
        </Alert>
      </Snackbar>    
      <Snackbar open={success} anchorOrigin={{ vertical, horizontal }} onClose={handleClose} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: 250 }} onClose={handleClose}>
          <Typography className='font-mont !text-white !pl-4'>
            NFT Acquistato!
          </Typography>
        </Alert>
      </Snackbar>   
      </Grid>
  );
}

export default Home;
