import { Grid, Typography } from "@mui/material";
import TimelineMobile from "./MobileComponents/TimelineMobile";
import Timeline from "./Timeline";


function Roadmap() {
  return (
    <Grid container sx={{ pl: { xs: 0, md: 1 }, mt: {xs: -20, md: -45} }}>
      <Grid item xs={12} sx={{ mt: { xs: 10, md: 18 }, pl: {xs: 2, md: 6} }}>
        <Typography component={'div'} className={'text-orange italic !font-bold font-garet'} sx={{fontSize:{xs: 40, md:72}, textAlign: {xs: 'center', md:'initial'}}}>
          ROADMAP
        </Typography>
      </Grid>
      <Grid item xs={12} className='!mt-12' display={{xs: 'none', md: 'initial'}}>
        <Timeline />
      </Grid>
      <Grid item xs={12} className='!mt-12' display={{xs: 'initial', md: 'none'}}>
        <TimelineMobile />
      </Grid>
    </Grid>
  );
}

export default Roadmap;
