export const MINT_SUCCESS = "MINT_SUCCESS";
export const MINT_FAILED = "MINT_FAILED";
export const SET_ERROR_BLOCKCHAIN = "SET_ERROR_BLOCKCHAIN";
export const SET_SUCCESS_BLOCKCHAIN = "SET_SUCCESS_BLOCKCHAIN";
export const CONNECT_WALLET = "CONNECT_WALLET";
export const DISCONNECT_WALLET = "DISCONNECT_WALLET";
export const CHAIN_ID_FAILED = "CHAIN_ID_FAILED";
export const WALLET_OF_OWNER_SUCCESS = 'WALLET_OF_OWNER_SUCCESS';
export const WALLET_OF_OWNER_FAILED = 'WALLET_OF_OWNER_FAILED';
export const TOKEN_URI_FAILED = 'TOKEN_URI_FAILED';
export const TOKEN_URI_SUCCESS = 'TOKEN_URI_SUCCESS';
export const SET_SHOW_POPUP = 'SET_SHOW_POPUP';