import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";
import WalletConnectProvider from "@walletconnect/web3-provider";

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: { //TODO da valutare le option
      infuraId: '62d4240de3e14c9b8049516fb9f4c98c',
      rpc: {
        1: "https://mainnet.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        3: "https://ropsten.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        4: "https://rinkeby.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        5: "https://goerli.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        137: "https://polygon-mainnet.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        80001: "https://polygon-mumbai.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538"
        // ...
      }
    }
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK, // Required
    options: {
      appName: "CCLUB", // Required
      infuraId: "9daf6478e2ea4d499748a8ddc1208538", // Required
      rpc: {
        1: "https://mainnet.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        3: "https://ropsten.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        4: "https://rinkeby.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        5: "https://goerli.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        137: "https://polygon-mainnet.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538",
        // ...
      }, // Optional if `infuraId` is provided; otherwise it's required
      darkMode: true // Optional. Use dark theme, defaults to false
    }
  }
};

const injected = new InjectedConnector({ //METAMASK
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 80001]
});

const walletconnect = new WalletConnectConnector({
  rpcUrl: 'wss://goerli.infura.io/ws/v3/9daf6478e2ea4d499748a8ddc1208538',
  //bridge: "https://bridge.walletconnect.org",
  qrcode: true,
});

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/9daf6478e2ea4d499748a8ddc1208538`,
  appName: "CROWDMOOVI",
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97, 137, 80001], //TODO valutare
  qrcode: true,
});

export const connectors = {
  injected: injected,
  walletConnect: walletconnect,
  coinbaseWallet: walletlink
};