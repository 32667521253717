//import Web3 from "web3";
//import Web3EthContract from "web3-eth-contract";
import {
    MINT_FAILED,
    MINT_SUCCESS,
    SET_ERROR_BLOCKCHAIN,
    SET_SUCCESS_BLOCKCHAIN,
    CONNECT_WALLET,
    DISCONNECT_WALLET,
    CHAIN_ID_FAILED,
    WALLET_OF_OWNER_SUCCESS,
    TOKEN_URI_SUCCESS,
    TOKEN_URI_FAILED,
    WALLET_OF_OWNER_FAILED,
    SET_SHOW_POPUP
} from "./types";
import { ethers, providers } from "ethers";
import { providerOptions, connectors } from "../../Utils/providerOptions";
import Web3Modal from "web3modal";
//import { contractAddress } from "../../Utils/config";
//simport { maticProvider } from "../../Utils/polygonProvider";
import abi from './abi.json'
//import config from './config.json'

var smartContract = {};
var provider;
var signer;
//var web3;
const maticWeb3 = ((window.ethereum != null) ? new ethers.providers.Web3Provider(window.ethereum) : ethers.providers.getDefaultProvider());
const CONTRACT_ADDRESS = '0x297703b49Ada427451daBEd8D21e8f51a20e7C40'
const web3Modal = new Web3Modal({
    cacheProvider: true, // optional
    providerOptions // required

});


export function connectAbi(contractAddress) {
    smartContract = new maticWeb3.eth.Contract(abi, contractAddress.CONTRACT_ADDRESS)
    return smartContract;
}

export function connectWallet(activate, contractAddress) {
    return async (dispatch) => {
        try {
            const instance = await web3Modal.connect();
            provider = await new providers.Web3Provider(instance);
            await provider._networkPromise
            signer = provider.getSigner();
            var chainId = provider._network.chainId;
            if (chainId !== 137) { //TODO CAMBIARE PER RETE UFFICIALE IN 137 // RETE TEST 80001
                dispatch({
                    type: CHAIN_ID_FAILED,
                    errorBoolean: true,
                    errorMessage: "Change to Polygon network or connect the wallet!",
                });
            } else {
                switch (provider.connection.url) {
                    case 'metamask':
                        activate(connectors.injected)
                        break;
                    case 'eip-1193:':
                        activate(connectors.walletConnect)
                        break;
                    case 'https://goerli-infura.wallet.coinbase.com': //TODO sistemare e trovare url corretto
                        activate(connectors.coinbaseWallet)
                        break;
                    default:
                        console.log("default status");
                        break;
                }
            }
            // if (accounts) {
            //     connectAbi(contractAddress);
            // }
            dispatch({
                type: CONNECT_WALLET
            });
        } catch (error) {
            console.error('connectWallet - Error: ', error)
        }
    }
};

export function disconnectWeb3Modal(deactivate) {
    return (dispatch) => {
        deactivate();
        web3Modal.clearCachedProvider();
        dispatch({
            type: DISCONNECT_WALLET
        });
    };
};




export function mint(data) {
    return async (dispatch) => {
        try {
            //connectAbi(config);
            if (signer !== undefined) {
                smartContract = new ethers.Contract(CONTRACT_ADDRESS, abi, signer)
            } else {
                dispatch({
                    type: MINT_FAILED,
                    errorBoolean: true,
                    errorMessage: 'Eseguire di nuovo il wallet connect!'
                });
            }
            //console.log(ethers.utils.parseUnits('0.1', 'gwei'))
            const nonce = await provider.getTransactionCount(CONTRACT_ADDRESS);
            const tx = smartContract.mint(data, {
                gasLimit: 160000,
                //gasPrice: ethers.utils.parseUnits('0.1', 'gwei'),
                //gasPrice: 1000000000,
                //gasPrice: 3000000,
                value: ethers.BigNumber.from("40000000000000000000"),
                //nonce: nonce
            });
            await tx.then(result => {
                result.wait().then(res => {
                    dispatch({
                        type: MINT_SUCCESS,
                        mint: result,
                        success: true,
                        successMessage: 'Mint success!'
                    });
                }).catch(e => {
                    if (e.message.includes('user rejected transaction'))
                        throw new Error('User rejected transaction!')
                    else
                        throw new Error(e)
                })
            }).catch(e => {
                if (e.message.includes('user rejected transaction'))
                        throw new Error('User rejected transaction!')
                dispatch({
                    type: MINT_FAILED,
                    errorBoolean: true,
                    errorMessage: e.message,
                });
            })
        } catch (err) {
            console.error('Mint - Error: ', err)
            if (err.message.includes('user rejected transaction')) {
                dispatch({
                    type: MINT_FAILED,
                    errorBoolean: true,
                    errorMessage: 'User rejected transaction!',
                });
            }
            else
                dispatch({
                    type: MINT_FAILED,
                    errorBoolean: true,
                    errorMessage: err.message,
                });
        }
    };
};

export function setErrorBoolean(errorBoolean = false, errorMessage = '') {
    return (dispatch) => {
        dispatch({
            type: SET_ERROR_BLOCKCHAIN,
            errorBoolean: errorBoolean,
            errorMessage: errorMessage
        });
    }
}

export function setSuccess(success = false, successMessage = '') {
    return (dispatch) => {
        dispatch({
            type: SET_SUCCESS_BLOCKCHAIN,
            success: success,
            successMessage: successMessage
        });
    }
}

export const tokenURIMethod = (tokenId) => {
    return async (dispatch) => {
        try {
            if (signer !== undefined) {
                smartContract = new ethers.Contract(CONTRACT_ADDRESS, abi, signer)
            }
            smartContract.tokenURI(tokenId)
                .then(async result => {
                    fetch(result, {
                        headers: {
                            Accept: "application/json",
                        }, //la fetch mi ritorna una risposta di http, quindi faccio .json() e recupero la risposta
                    }).then(resultFetch => resultFetch.json())
                        .then(json => {
                            dispatch({
                                type: TOKEN_URI_SUCCESS,
                                tokenURI: json,
                            });
                        }
                        );
                }).catch(err => {
                    dispatch({
                        type: TOKEN_URI_FAILED,
                        errorBoolean: true,
                        errorMethod: err.message,
                    });
                });
        } catch (err) {
            dispatch({
                type: TOKEN_URI_FAILED,
                errorBoolean: true,
                errorMethod: err.message,
            });
        }
    };
};

export const walletOfOwnerMethod = (smartContract, address) => {
    return async (dispatch) => {
        try {
            if (signer !== undefined) {
                smartContract = new ethers.Contract(CONTRACT_ADDRESS, abi, signer)
            }
            smartContract.walletOfOwner(address)
                .then(result => {
                    dispatch({
                        type: WALLET_OF_OWNER_SUCCESS,
                        walletOfOwner: result,
                    });
                }).catch(err => {
                    dispatch({
                        type: WALLET_OF_OWNER_FAILED,
                        errorBoolean: true,
                        errorMethod: err.message,
                    });
                });
        } catch (err) {
            dispatch({
                type: WALLET_OF_OWNER_FAILED,
                errorBoolean: true,
                errorMethod: err.message,
            });
        }
    };
};

export function setShowPopupLoading(value) {
    return dispatch => {
        return dispatch({
            type: SET_SHOW_POPUP,
            showSuspenseImg: value
        })
    }
}