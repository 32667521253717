import { combineReducers } from "redux";
import { applicationReducer } from "./Application/ApplicationReducer";
import { blockchainReducer } from "./Blockchain/BlockchainReducer";


const rootReducer = combineReducers({
    blockchain: blockchainReducer,
    application: applicationReducer
});

export default rootReducer;