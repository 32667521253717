import {
    CHAIN_ID_FAILED,
    CONNECT_WALLET,
    MINT_FAILED,
    MINT_SUCCESS,
    SET_ERROR_BLOCKCHAIN,
    SET_SUCCESS_BLOCKCHAIN,
    WALLET_OF_OWNER_SUCCESS,
    TOKEN_URI_SUCCESS,
    TOKEN_URI_FAILED,
    WALLET_OF_OWNER_FAILED,
    SET_SHOW_POPUP
} from "./types";

export function blockchainReducer(
    state = {
        errorBoolean: false,
        errorMessage: '',
        mint: {},
        success: false,
        successMessage: '',
        walletOfOwner: '',
        tokenURI: '',
        showSuspenseImg: false
    },
    action
) {
    switch (action.type) {
        case MINT_SUCCESS:
            return Object.assign({}, state, {
                mint: action.mint,
                success: action.success,
                successMessage: action.successMessage,
                showSuspenseImg: false
            });
        case MINT_FAILED:
            return Object.assign({}, state, {
                errorBoolean: action.errorBoolean,
                errorMessage: action.errorMessage,
                showSuspenseImg: false
            });
        case SET_ERROR_BLOCKCHAIN:
            return Object.assign({}, state, {
                errorBoolean: action.errorBoolean,
                errorMessage: action.errorMessage
            });
        case SET_SUCCESS_BLOCKCHAIN:
            return Object.assign({}, state, {
                success: action.success,
                successMessage: action.successMessage
            });
        case CONNECT_WALLET:
            return Object.assign({}, state, {
            });
        case CHAIN_ID_FAILED:
            return Object.assign({}, state, {
                errorBoolean: action.errorBoolean,
                errorMessage: action.errorMessage
            });
        case WALLET_OF_OWNER_SUCCESS:
            return Object.assign({}, state, {
                walletOfOwner: action.walletOfOwner
            });
        case WALLET_OF_OWNER_FAILED:
            return Object.assign({}, state, {
                errorMsg: action.errorMethod
            });
        case TOKEN_URI_SUCCESS:
            return Object.assign({}, state, {
                tokenURI: action.tokenURI
            });
        case TOKEN_URI_FAILED:
            return Object.assign({}, state, {
                errorMsg: action.errorMethod
            });
        case SET_SHOW_POPUP:
            return Object.assign({}, state, {
                showSuspenseImg: action.showSuspenseImg
            });
        default:
            return state;
    }
}