import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useState } from 'react';
import styles from '../CSS/Descrizione.module.css'

function Descrizione(props) {
    const [userHasScrolled, setUserHasScrolled] = useState(false);

    window.onscroll = function () {
        setUserHasScrolled(true)
    }
    return (
        <Grid container className={'bg-violet '} sx={{ pt: { xs: 0, md: 0 } }}>
            {/* ------------- PRIMO GRID 70% PAGINA ------- */}
            <Grid container sx={{ pl: { xs: 2, md: 14 }, pr: { xs: 2, md: 0 } }}>
                <Grid item style={{ textAlign: 'center' }} xs={12} md={6} sx={{ pb: { xs: 2, md: 8 } }}>
                    <Typography component={'h2'} style={{ textAlign: 'center', height: 70 }} className={styles.mt_10p + ' text-white italic font-mont'} sx={{ fontSize: { xs: 40, md: 80 } }}>
                        <span>NFT REWARD</span>
                    </Typography>
                    <Typography component={'h2'} style={{ textAlign: 'center' }} className={styles.mt_10p + ' text-white italic font-mont inline-flex'} sx={{ fontSize: { xs: 40, md: 80 } }}>
                        CROWD<span className='text-orange inline-flex'>FUNDING</span>
                    </Typography>
                    <Typography style={{ textAlign: 'center' }} component={'div'} className={styles.mt_10p + ' !text-xl text-white font-garet'} sx={{ width: { xs: 'initial' } }}>
                        La prima piattaforma di Crowdfunding tramite
                        NFT che permette agli artisti cinematografici di
                        finanziare i propri prodotti in autonomia, con
                        innumerevoli vantaggi.
                        <Typography component={'p'} className={styles.mt_20p + ' text-center-browser font-garet !text-xl text-orange text-center pt-5'}>
                            <Button
                                onClick={() => props.executeScroll()}
                                className={'!p-6 font-mont self-center button_marketplace'}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                style={{height: 60, fontSize: 20}}
                            >
                                GENESIS NFT
                            </Button>
                        </Typography>
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ pl: { xs: 0 } }} className='text-right-browser'>
                    <Box component={'img'} src='/Images/rullino.png' style={{ width: '75%'}}/>
                </Grid>
            </Grid>
            <Grid item style={{ textAlign: "center" }} xs={12} className='!self-center'>
                <Typography component={'h2'} className={styles.sloganCss + ' font-mont inline-flex'} sx={{ fontSize: { xs: 40, md: 80 } }}>
                    <Grid container >
                        {/* FINE SLOGAN MOBILE */}
                        <Grid item xs={12} sx={{ display: { xs: 'none', md: 'initial' } }}>
                            <span >{userHasScrolled ? <div className={styles.sloganAnimation} style={{fontSize: 'smaller'}}>DAGLI ARTISTI, AGLI ARTISTI, PER L’ARTE</div> : null}</span>
                        </Grid>
                    </Grid>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Descrizione;
