import { Box, Button, Grid, Typography } from "@mui/material";
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Zoom } from "react-reveal";
import { connectWallet, mint, setShowPopupLoading, tokenURIMethod, walletOfOwnerMethod } from "../Redux/Blockchain/BlockchainAction";
import { contractAddress,CLIENT_ID, NFT_NUMBER } from "../Utils/config";
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";



function MintNft(props) {

    const { active, account, activate } = useWeb3React('polygon');
    const dispatch = useDispatch();
    const [background, setBackground] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [img, setImg] = useState('');
    const mintVal = useSelector(state => state.blockchain.mint);
    const walletOfOwner = useSelector(state => state.blockchain.walletOfOwner);
    const tokenURI = useSelector(state => state.blockchain.tokenURI)

    useEffect(() => {
        dispatch(walletOfOwnerMethod(contractAddress, account));
    }, [mintVal]);

    useEffect(() => {
        // if(walletOfOwner[walletOfOwner.length - 1] !== totalSupply){
            setTimeout(() => {
                dispatch(tokenURIMethod(walletOfOwner[walletOfOwner.length - 1]))
              }, 1500);
        // }
    }, [walletOfOwner]);

    useEffect(() => {
        if(tokenURI !== ''){
        setBackground(true)
        props.executeScroll()
        setTimeout(() => {
            setShowImage(true);
            props.executeScroll()
        }, 8000);
        setTimeout(() => {
            setBackground(false)
            setShowImage(false);
            props.executeScroll()
        }, 15000);
        setImg(tokenURI.image)
    }
    }, [tokenURI]);

    const handleConnect = () => {
        if (!active)
            dispatch(connectWallet(activate, contractAddress));
        else {
            dispatch(setShowPopupLoading(true))
            dispatch(mint(account));
        }
    };

    return (
        <>
            {background ?
                <Grid container sx={{ pl: { xs: 0, md: 0 }, height: { xs: 1000, md: 900, xl: 1000 } }} className='!relative !mt-32'>
                    <Grid item xs={12} className='place-items-center text-center-browser !flex justify-center'>
                        <Box component={'video'} autoPlay src={'/Video/show.mp4'} />
                        {showImage &&
                            <Zoom left>
                                <Box component={'img'} src={'https://ipfs.io/ipfs/'+img.split('ipfs://')[1]} className="!z-10 relative" />
                                {/*<Button onClick={() => OnCloseShow()}> OK </Button>*/}
                            </Zoom>
                        }
                    </Grid>
                </Grid>
                :
                <Grid container sx={{ pl: { xs: 0, md: 0 }, height: { xs: 1000, md: 900, xl: 1000 } }} className='bg-show !mt-32'>
                    <Grid item xs={12} className={'!mt-28 text-center-browser'}>
                        <Typography component={'div'} className={'text-white italic !font-bold font-mont'} sx={{ fontSize: { xs: 40, md: 72 } }} ref={props.myRef}>
                            OTTIENI IL TUO
                            <br />
                            "<span className='text-violet'>GENESIS</span> <span className='text-orange'>NFT</span>"
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={'text-center-browser'}>
                        <Grid container alignItems="center">
                            <Grid item xs={12} className={'text-center-browser'}>
                                <Typography component={'div'} className={'text-white !text-2xl font-garet !font-bold'} sx={{ width: { xs: 300, md: 600 } }}>
                                    Collega il tuo wallet ed assicurati di essere su rete Polygon
                                    o usa una carta di debito o credito.
                                    <br />
                                    <br />
                                    Acquista la tua Genesis NFT Card e preparati ad una esperienza di Crowdfunding come non avevi mai visto prima!
                                </Typography>
                                {active &&
                                    <Typography component={'div'} className={'text-white !text-xl font-garet !font-bold !mt-20'} sx={{ width: { xs: 350, md: 500 } }}>
                                        {account}
                                    </Typography>
                                }
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <Button
                                    onClick={() => handleConnect()}
                                    disabled
                                    className={'!text-5xl !py-5 font-mont self-center button_marketplace !w-96 ' + (!active ? '!mt-20' : '')}
                                    style={{ height: 70, width: active ? 180 : 'initial', marginBottom: 'auto' }}
                                    sx={{ my: 2, color: 'white', display: 'block' }}
                                >
                                    <span className={'!text-3xl'}>{active ? 'MINT' : 'CONNECT WALLET'}</span>
                                </Button>
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <CrossmintPayButton
                                   className={'!text-3xl !py-5 font-mont self-center button_marketplace uppercase ' + (!active ? '!mt-20' : '')}
                                   style={{ height: 70, width: 'initial' }}
                                   disabled
                                   sx={{ my: 2, color: 'white', display: 'block' }}
                                    clientId= {CLIENT_ID}
                                    environment="production" //staging rimuovere fuori dal test
                                    mintConfig={{
                                        type: "erc-721",
                                        quantity: {NFT_NUMBER},
                                        totalPrice: '1'
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    );
}

export default MintNft;
