import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import SidebarMobile from '../Components/MobileComponents/SidebarMobile';
import { Box, Grid, Link, Typography } from '@mui/material';
import styles from '../CSS/Team.module.css'
import { Slide } from 'react-reveal';

function Team() {
    return (
        <Slide right>
            <Grid container className='!h-screen'>
                <Grid item xs={12} md={1} lg={1} display={{ xs: 'initial', md: 'none' }}>
                    <SidebarMobile />
                </Grid>
                <Grid item xs={12} md={12} lg={12} sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <ResponsiveAppBar />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className='flex !justify-center bg-white'>
                    <Typography component={'h1'} className='text-black font-mont italic py-10' sx={{ fontSize: { xs: 30, md: 80 }}}>
                        TEAM
                    </Typography>
                </Grid>

                {/*  CEO */}

                <Grid item xs={12} md={12} lg={12} className='!justify-center bg-white'>
                    <Grid container>
                        <Grid item xs={12} className='bg-white text-center-browser'>
                            <Typography component={'h1'} className='text-black text-left font-garet ' sx={{ width: { xs: '90%', md: '60%' }, fontSize: { xs: 13, md: 20 } }}>
                                CrowdMoovi viene creata da artisti per gli artisti con la volontà di
                                andare a costruire un ecosistema che permetta ad ognuno di
                                esprimere liberamente e al meglio la propria arte.
                                <br />
                                Un team affiatato, che unisce conoscenze e competenze in ambito
                                Crypto, NFTs, cinema e che ci tiene a portare in questo settore un più
                                ampio senso di comunità e libertà artistica.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} className='bg-white text-center-browser !mt-20'>
                            <div className={styles.circle_img + ' ' + styles.one + ' ' + styles.borders} />
                            <Typography component={'h1'} className='text-black font-mont !text-3xl w-2/4 pt-3'>
                                LORENZO
                            </Typography>
                            <Typography component={'p'} className='text-black font-garet !text-xl w-2/4 pt-1'>
                                Chief Executive Officer
                            </Typography>
                            <Link href='https://www.linkedin.com/in/lorenzo-marino-a62a081b7/' target='_blank' rel='noopener noreferrer'>
                                <Box component={'img'} src='/Images/linkedin.png' />
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={6} className='bg-white text-center-browser !mt-20'>
                            <div className={styles.circle_img + ' ' + styles.two + ' ' + styles.borders} />
                            <Typography component={'h1'} className='text-black font-mont !text-3xl w-2/4 pt-3'>
                                GIULIO
                            </Typography>
                            <Typography component={'p'} className='text-black font-garet !text-xl w-2/4 pt-1'>
                                Chief Marketing Officer
                            </Typography>
                            <Link href='https://www.instagram.com/giuli0mazza?utm_source=qr' target='_blank' rel='noopener noreferrer'>
                                <Box component={'img'} src='/Images/insta.png' />
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>

                {/* TEAM DI SVILUPPO */}

                <Grid item xs={12} md={12} lg={12} className='!justify-center bg-white !mb-20'>
                    <Grid container>
                        <Grid item xs={12} className='bg-white text-center-browser pt-20 pb-10'>
                            <Typography component={'h1'} className='text-orange font-mont !text-4xl italic'>
                                TEAM PARTNER DI SVILUPPO
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className='bg-white text-center-browser !mt-20'>
                            <div className={styles.circle} />
                            <Typography component={'h1'} className='text-black font-mont !text-3xl w-2/4 pt-3'>
                                GIUSEPPE
                            </Typography>
                            <Typography component={'p'} className='text-black font-garet !text-xl w-2/4 pt-1'>
                                Blockchain Developer
                            </Typography>
                            <Link href='https://t.me/orlandoFur' target='_blank' rel='noopener noreferrer'>
                                <Box component={'img'} src='/Images/telegram.png' />
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={4} className='bg-white text-center-browser !mt-20'>
                            <div className={styles.circle} />
                            <Typography component={'h1'} className='text-black font-mont !text-3xl w-2/4 pt-3'>
                                GIOVANNI
                            </Typography>
                            <Typography component={'p'} className='text-black font-garet !text-xl w-2/4 pt-1'>
                                Web3 Developer
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={4} className='bg-white text-center-browser !mt-20'>
                            <div className={styles.circle} />
                            <Typography component={'h1'} className='text-black font-mont !text-3xl w-2/4 pt-3'>
                                FEDERICO
                            </Typography>
                            <Typography component={'p'} className='text-black font-garet !text-xl w-2/4 pt-1'>
                                Web3 Developer
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Slide>
    );
}

export default Team;
