import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import styles from '../CSS/ResponsiveAppBar.module.css'
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../Redux/Application/ApplicationAction';

const pages = ['HOME','WHITEPAPER', 'TEAM', 'Marketplace'];


function ResponsiveAppBar() {
  const dispatch = useDispatch();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const page = useSelector(state => state.application.page);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  var bgColor = page === 'team' ?  'bg-radiant-white-to-violet' : 'bg-violet';
  var image = page === 'team' ? '/Images/logo.png' : '/Images/logo.jpg' ;
  return (
    <AppBar position="static" className={bgColor}>
      <Container className={styles.min_h_200 + ' !pl-0 !mx-0 !max-w-full'}>
        <Toolbar disableGutters>
          <Grid container>
            <Grid item sx={{ display: { xs: 'none', md: 'inherit' } }} md={4}>
              <Typography
                variant="h6"
                noWrap
                component="a"
                href="/"
                sx={{
                  mr: 2,
                  display: { xs: 'none', md: 'flex' },
                  fontFamily: 'monospace',
                  fontWeight: 700,
                  letterSpacing: '.3rem',
                  color: 'inherit',
                  textDecoration: 'none',
                }}
              >
                <Box component={'img'} src={image} style={{width: 400}}/>
              </Typography>
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center" key={page}>{page}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Grid>
            <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
            

            <Grid item xs={8} className={'flex'}>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className={'justify-end'}>
                {pages.map((page) => {
                  if (page === 'Marketplace') {
                    return (
                      <Button
                        href='https://opensea.io/crowdmoovi'
                        target='_blank'
                        rel='noopener noreferrer'
                        key={page}
                        className={'!text-base !p-5 !font-semibold self-center button_marketplace'}
                        sx={{ my: 2, color: 'white', display: 'block' }}
                        disabled
                      >
                        {page}
                      </Button>
                    )
                  } else {
                    return (
                      <Button
                        key={page}
                        onClick={() => dispatch(setPage(page.toLowerCase()))}
                        className={'!text-xl !p-5 !font-semibold !mr-8' }
                        sx={{ my: 2, color: 'white', height: 30, alignSelf: 'center' }}
                      >
                        {page}
                      </Button>
                    )
                  }
                })}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;