import {
    Button,
    Grid,
    Typography,
} from "@mui/material";

function PossedereCrowd(props) {
    return (
        <Grid container className={'pl-28'} sx={{ pl: { xs: 0, md: 1 } }}>
            <Grid item xs={12} sx={{mt: {xs: 10, md: 18}}}>
                <Typography component={'div'} className={'text-violet italic !font-black font-mont !text-center'}
                    sx={{fontSize: {xs: 36, md: 60}, lineHeight: 1}}>
                    <span className="text-orange inline-flex">PERCHE'</span> ACQUISTARE
                    UN <span className="text-orange inline-flex">GENESIS</span> NFT?
                </Typography>
            </Grid>
            <Grid item xs={12} className='!mt-12 text-center-browser'>
                <Grid container alignItems="flex-start" className="!justify-center">
                    <Grid item xs={12} className={' text-center-browser '}>
                        <Typography component={'div'} className={'text-violet !text-3xl font-mont !text-center raccoltaInvestimentiShadow'}>
                            RACCOLTA INVESTIMENTI
                        </Typography>
                    </Grid>
                    <Grid item lg={3.5} xs={11} style={{ border: 'orange', borderStyle: 'solid', borderWidth: 6, marginTop:20}} className='!pb-5'>
                        <Typography component={'h1'} className={'text-violet !text-3xl !font-bold font-mont !p-5'}>
                            <span className='text-orange !font-bold'>CON</span> ALMENO UN GENESIS <span className='text-orange !font-bold'>NFT</span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                        <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3"> Commissioni sull'acquisto di NFTs degli artisti sul marketplace: <span className='text-orange !font-bold !inline-flex'>0.8%</span></span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3"> Commissioni per l'artista sul totale dei fondi raccolti:<span className='text-orange !font-bold'>1%</span></span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3"> Accesso anticipato a tutte le future collezioni NFT</span>
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={11} style={{ border: '#7e7bfa', borderStyle: 'solid', borderWidth: 6, marginLeft:3, marginTop:20}} className='!pb-5'>
                        <Typography component={'h1'} className={'text-violet !text-3xl !font-bold font-mont !p-5'}>
                            <span className='text-orange !font-bold'>SENZA</span> <br/>GENESIS <span className='text-orange !font-bold'>NFT</span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3 !pr-5"> Commissioni sull'acquisto di NFTs degli artisti sul marketplace: <span className='text-orange !font-bold'>2%</span></span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3 !pr-5"> Commissioni per l'artista sul totale dei fondi raccolti: <span className='text-orange !font-bold'>3%</span></span>
                        </Typography>
                    </Grid>
                    <Grid item lg={4} xs={11} style={{marginTop:20, marginLeft:5}} className='!pb-5'>
                        <Typography component={'h1'} className={'text-violet !text-3xl !font-bold font-mont !p-5'}>
                            <span style={{ color: 'red' }} className='!font-bold'>FUORI</span> DA <br/> CROWDMOOVI
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3 !mr-16"> Commissioni di elaborazione pagamento su ogni contributo: <span style={{ color: 'red' }} className='!font-bold'>3/5%</span></span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3"> Commissioni per l'artista sul totale dei fondi raccolti:<span style={{ color: 'red' }} className='!font-bold'> 5/8%</span></span>
                        </Typography>
                        <Typography component={'h4'} className={'text-black !text-xl font-garet !font-medium !text-left !inline-flex'} style={{float: 'left'}}>
                            <span style={{ float: 'left', marginLeft: 30 }}>&#8226;</span><span className="!ml-3"> <span style={{ color: 'red' }} className='!font-bold'>NO</span> royalties</span>
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} className={'text-center-browser !mt-12'}>
                <Button
                    onClick={() => props.executeScroll()}
                    className={'!text-2xl !p-5 font-mont self-center button_marketplace '}
                    style={{ height: 70 }}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                >
                    ACQUISTA ORA
                </Button>
            </Grid>
        </Grid>
    );
}

export default PossedereCrowd;
