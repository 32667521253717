import { createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { applyMiddleware } from '@reduxjs/toolkit';
import { composeWithDevTools } from "@redux-devtools/extension";


const store = createStore(rootReducer,
  composeWithDevTools(
    applyMiddleware(
      thunk
    )
  ));

export default store;