import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setPage } from '../../Redux/Application/ApplicationAction';
import MenuIcon from '@mui/icons-material/Menu';


const pages = ['HOME', 'WHITEPAPER', 'TEAM', 'MARKETPLACE'];


function ResponsiveAppBar() {
  const dispatch = useDispatch();
  const page = useSelector(state => state.application.page);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
  };


  var bgColor = page === 'team' ? 'bg-radiant-white-to-violet' : 'bg-violet';
  var image = page === 'team' ? '/Images/logo.png' : '/Images/logo.jpg';
  return (
    <AppBar position="static" className={bgColor + ' !shadow-none'}>
      <Container className={' !pl-0 !mx-0 !max-w-full'}>
        <Toolbar disableGutters>
          <Grid container>
            <Grid item xs={12} className={'flex'}>
              <Box component={'img'} src={image} sx={{ height: 90 }} />
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'right' }}>
                <IconButton
                  size="large"
                  aria-label="Modal"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: 'block', md: 'none' },
                  }}
                >
                  {pages.map((page) => {
                    if (page === 'MARKETPLACE') {
                      return (
                        <MenuItem key={page} onClick={handleCloseNavMenu} className='!justify-center !bg-opacity-0'>
                          <Button className='font-mont !border-b button_marketplace !p-5 !text-white'
                            href='https://opensea.io/crowdmoovi'
                            target='_blank'
                            rel='noopener noreferrer'
                            key={page}
                            disabled
                          >
                            {page}
                          </Button>
                        </MenuItem>
                      )
                    } else {
                      return (
                        <MenuItem key={page} onClick={handleCloseNavMenu} className='!justify-center !bg-opacity-0'>
                          <Button
                            className='font-mont !border-b !text-black'
                            onClick={() => dispatch(setPage(page.toLowerCase()))}
                          >
                            {page}
                          </Button>
                        </MenuItem>
                      )
                    }
                  })}
                </Menu>
              </Box>
              {/* <Grid container>
                {pages.map((page) => {
                  if (page === 'Marketplace') {
                    return (
                        <Grid item xs={12} className='text-center-browser !px-3'>
                      <Button
                        href='https://opensea.io/crowdmoovi'
                        target='_blank'
                        rel='noopener noreferrer'
                        key={page}
                        className={'!text-base !p-5 !font-semibold self-center button_marketplace'}
                        sx={{ mb: 5, color: 'white', display: 'block' }}
                      >
                        {page}
                      </Button>
                      </Grid>
                    )
                  } else {
                    return (
                        <Grid item xs={4} className='text-center-browser'>
                      <Button
                        key={page}
                        onClick={() => dispatch(setPage(page.toLowerCase()))}
                        className={'!text-base !p-5 !font-semibold'}
                        sx={{ color: 'white', display: 'block' }}
                      >
                        {page}
                      </Button>
                      </Grid>
                    )
                  }
                })}
                </Grid>*/}
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar >
  );
}
export default ResponsiveAppBar;