import { Grid, Typography } from "@mui/material";


function Footer() {
    return (
        <Grid container className={'bg-violet'} sx={{height: {xs: 100, md: 128}}}>
            <Grid item xs={12} sx={{mt: {xs: 0, md:8}, textAlign: {xs: 'center', md: 'right'}, pr: {xs: 0, md: 3}, alignSelf: {xs: 'center', md: 'initial'}}}>
                <Typography component={'div'} className={'text-white font-garet'}>
                    Hai altre domande? Contattaci a <span className="!font-bold">info@crowdmoovi.com</span>
                </Typography>
            </Grid>
        </Grid>
    );
}

export default Footer;
