import * as React from 'react';
import { Box, Grid } from '@mui/material';


export default function TimelineMobile() {  
  return (
    // <Timeline position="alternate">
    //   <TimelineItem>
    //     <TimelineOppositeContent color="#4c00b7" style={{fontWeight: 600}}>
    //       SITO WEB E COLLEZIONE NFT
    //     </TimelineOppositeContent>
    //     <TimelineSeparator>
    //       <TimelineDot className='bg-black '/>
    //       <TimelineConnector className='bg-black' style={{width: 4}}/>
    //     </TimelineSeparator>
    //     <TimelineContent><span className='data-year-mobile' >Q1 2023</span></TimelineContent>
    //   </TimelineItem>
    //   <TimelineItem>
    //     <TimelineOppositeContent color="#4c00b7" style={{fontWeight: 600}}>
    //       VERSIONE BETA MARKETPLACE
    //     </TimelineOppositeContent>
    //     <TimelineSeparator>
    //       <TimelineDot className='bg-black'/>
    //       <TimelineConnector className='bg-black' style={{width: 4}}/>
    //     </TimelineSeparator>
    //     <TimelineContent><span className='data-year-mobile' >Q2 2023</span></TimelineContent>
    //   </TimelineItem>
    //   <TimelineItem>
    //     <TimelineOppositeContent color="#4c00b7" style={{fontWeight: 600}}>
    //       NUOVE ASSUNZIONI
    //     </TimelineOppositeContent>
    //     <TimelineSeparator>
    //       <TimelineDot className='bg-black'/>
    //       <TimelineConnector className='bg-black' style={{width: 4}}/>
    //     </TimelineSeparator>
    //     <TimelineContent><span className='data-year-mobile' >Q3 2023</span></TimelineContent>
    //   </TimelineItem>
    //   <TimelineItem>
    //     <TimelineOppositeContent color="#4c00b7" style={{fontSize: 15, fontWeight: 600}}>
    //       AGGIORNAMENTO SITO WEB
    //     </TimelineOppositeContent>
    //     <TimelineSeparator>
    //       <TimelineDot className='bg-black'/>
    //       <TimelineConnector className='bg-black' style={{width: 4}}/>
    //     </TimelineSeparator>
    //     <TimelineContent><span className='data-year-mobile' >Q4 2023</span></TimelineContent>
    //   </TimelineItem>
    //   <TimelineItem>
    //     <TimelineOppositeContent color="#4c00b7" style={{fontSize: 15, fontWeight: 600}} >
    //       AGGIORNAMENTO MARKETPLACE
    //     </TimelineOppositeContent>
    //     <TimelineSeparator>
    //       <TimelineDot className='bg-black'/>
    //       <TimelineConnector className='bg-black' style={{width: 4}}/>
    //     </TimelineSeparator>
    //     <TimelineContent><span className='data-year-mobile' >Q1 2024</span></TimelineContent>
    //   </TimelineItem>
    // </Timeline>
    <Grid container>
      <Grid item xs={12} className='text-center-browser'>
        <Box component={'img'} src='/Images/timelineMobile.png' />
      </Grid>
    </Grid>
  );
}