import { useSelector } from 'react-redux';
import CustomizedSnackbars from './Components/CustomizedSnackbars';
import Home from './Pages/Home';
import Team from './Pages/Team';
import Whitepaper from './Pages/Whitepaper';

function App() {
  const page = useSelector(state => state.application.page)
  return (
    <div id='style-2'>
      {page === 'home' &&
        <Home />
      }
      {page === 'whitepaper' &&
        <Whitepaper />
      }
      {page === 'team' &&
        <Team />
      }
      <CustomizedSnackbars />
    </div>
  );
}

export default App;
