import { Box, Grid, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import styles from '../../CSS/PercheCrowdmoovi.module.css'

export default function PercheCrowdmooviMobile() {
    var accordionBody = [
        {
            'domanda':
                <Typography component={'div'} className={'text-orange !text-1xl !font-bold font-garet'}>
                    RISPARMIO PER GLI ARTISTI
                </Typography>,
            'risposta':
                <Typography style={{ fontSize: 10 }} component={'div'} className={'text-violet !font-bold font-garet'}>
                    SI APPLICA UNA COMMISSIONE SUI FONDI RACCOLTI SOLAMENTE SE IL PROGETTO RAGGIUNGE L'OBIETTIVO DI FINANZIAMENTO.
                    <br />
                    <br />
                    QUESTA COMMISSIONE (5-8% SU PIATTAFORME ESTERNE), SU CROWDMOOVI PARTE DA UN 3% PER ARRIVARE ALL'1% SE SI POSSIEDE UN "GENESIS NFT".
                    <Box component={'img'} src='/Images/graficoATorta.png' />
                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-orange !text-1xl !font-bold font-garet'}>
                    ROYALTIES E COMMUNITY
                </Typography>,
            'risposta':
                <Typography style={{ fontSize: 10 }} component={'div'} className={'text-violet  !font-bold font-garet'}>
                    CREA LA TUA CAMPAGNA E GUADAGNA ROYALTIES FINO AL <span className='text-orange'>10%</span> SU OGNI RIVENDITA DELLE NFT CARDS CREATE DA TE.
                    <br />
                    <br />
                    UTILIZZA LE TUE CARDS PER OFFRIRE IMPERDIBILI VANTAGGI E CREARE UNA SOLIDA <span className='text-orange'>COMMUNITY</span> DI PERSONE APPASSIONATE AL PROGETTO.

                </Typography>
        },
        {
            'domanda':
                <Typography component={'div'} className={'text-violet !text-1xl !font-bold font-garet'}>
                    PIU' RICOMPENSE PER I SOSTENITORI
                </Typography>,
            'risposta':
                <Typography style={{ fontSize: 10 }} component={'div'} className={'text-violet !font-bold font-garet'}>
                    GRAZIE ALLE NFT CARDS GLI ARTISTI POSSONO RICOMPENSARE I SOSTENITORI CON UN' INFINITA' DI VANTAGGI FISICI E/O DIGITALI.
                    <br />
                    <br />
                    UNA CARD RAPPRESENTA UNA <span className='text-orange'>FRAZIONE DEL DIRITTO D'AUTORE DEL FILM</span>, OLTRE AI VANTAGGI GIÀ VISIONATI, E PUÒ ESSERE SCAMBIATA O RIVENDUTA, A DIFFERENZA DEI VANTAGGI DELLE ALTRE PIATTAFORME DI CROWDFUNDING.
                </Typography>
        }
    ]
    return (
        <Grid container className='h-full w-full container_grid'>
            {/* ----- TITOLO ------- */}
            <Grid item xs={12} className={'item_grid'}>
                <Typography component={'div'} className={'!text-2xl text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                    COME FUNZIONA
                    <br />
                    CROWD<span className='text-orange inline-flex'>MOOVI?</span>
                </Typography>
            </Grid>
            {/* PRIMA DESCRIZIONE INIZIO*/}
            <Grid item xs={12} lg={12} style={{ marginTop: 70 }} className={'text-center'}>
                <Grid container>
                    <Grid item xs={1} >
                        <Box
                            className={styles.cinepresa1Mobile}
                            component={'img'}
                            src='/Images/cinepresa1.png'
                            sx={{
                                height: 100,
                                width: 190,
                                maxHeight: { xs: 180, md: 180 },
                                maxWidth: { xs: 370, md: 370 },
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography style={{ textAlign: 'center', paddingRight:30,marginTop: -90, fontSize: 15 }} component={'div'}
                            className={'  text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                            <span className='text-orange'>CREA IL TUO &nbsp;</span>
                            PROFILO
                            <span className='text-orange'>&nbsp; E LA <br /> TUA CAMPAGNA</span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: -25 }}>
                <Typography style={{ fontSize: 10 }} component={'h4'} className={' text-violet text-center pt-5 block font-mont'} sx={{ px: { xs: 6, md: 0 } }}>
                    &#8226;  INSERISCI I TUOI DATI, REGISTRATI E VAI ALLA SEZIONE “LE MIE CAMPAGNE”
                    <br />
                </Typography>
                <Typography component={'h4'} style={{ marginTop: -15, fontSize: 10 }} className={' text-violet  text-center pt-5 block font-mont'} sx={{ px: { xs: 6, md: 0 } }}>
                    &#8226; &nbsp; &nbsp; &nbsp; &nbsp;DUNQUE SERVIRA' SOLO INSERIRE LE INFORMAZIONI RILEVANTI RIGUARDO AL PROGETTO
                </Typography>
            </Grid>
            <Grid item xs={12} className='text-center-browser'>
                <Box
                    component={'img'}
                    src='/Images/grafica_1.png'
                    sx={{
                        width: 250,
                        marginTop: 3
                    }}
                />
            </Grid>
            {/* PRIMA DESCRIZIONE FINE*/}
            {/* SECONDA DESCRIZIONE INIZIO*/}
            <Grid item xs={12} lg={12} style={{ marginTop: 100 }} className={'text-center'}>
                <Grid container justifyContent='right' spacing={1}>
                    <Grid item xs={10} >
                        <Typography component={'div'} style={{ textAlign: 'center', marginBottom: '50px', marginTop: -120, fontSize: 15, paddingRight: 10 }} className={' text-violet pt-14 font-mont ' + styles.center_abs_text}>
                            <span className='text-orange inline-flex'>CREA LE TUE &nbsp;</span>
                            <br />
                            NFT CARDS
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            className={styles.clapperBoard1Mobile}
                            component={'img'}
                            src='/Images/clapperboard1.png'
                            sx={{
                                height: 60,
                                width: 130,
                                maxHeight: { xs: 135, md: 135 },
                                maxWidth: { xs: 320, md: 320 },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginTop: -15 }}>
                        <Typography component={'h4'} style={{ marginTop: -40, fontSize: 10 }} className={'!text-1xl text-violet  text-center pt-5 block font-mont'} sx={{ px: { xs: 4, md: 2 } }}>
                            &#8226; &nbsp; &nbsp;STABILISCI PREZZO, ROYALTIES ED ASSOCIA I VANTAGGI ALLE CARDS, TRA CUI LA <span className='font-garet'>%</span> SUL DIRITTO D'AUTORE SULL'OPERA E ULTERIORI VANTAGGI FISICI<span className='font-garet'>/</span>DIGITALI
                        </Typography>
                        <Typography component={'h4'} style={{ marginTop: -15, fontSize: 10 }} className={'!text-1xl text-violet  text-center pt-5 block font-mont'} sx={{ px: { xs: 4, md: 2 } }}>
                            &#8226; &nbsp; &nbsp; &nbsp; &nbsp; CREA IL DESIGN DELLE NFT CARDS O AGGIUNGILO IN UN SECONDO MOMENTO
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className='text-center-browser'>
                <Box
                    component={'img'}
                    src='/Images/grafica_2.png'
                    sx={{
                        width: 250,
                        marginTop: 3
                    }}
                />
            </Grid>
            {/* SECONDA DESCRIZIONE FINE */}
            {/* TERZA DESCRIZIONE INIZIO */}
            <Grid item xs={12} lg={10} className={'text-center'}>
                <Grid container>
                    <Grid item xs={1} style={{ marginTop: 80 }}>
                        <Box
                            className={styles.cinepresa3Mobile}
                            component={'img'}
                            src='/Images/cinepresa3.png'
                            sx={{
                                height: 100,
                                width: 190,
                                maxHeight: { xs: 180, md: 180 },
                                maxWidth: { xs: 370, md: 370 },
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Typography style={{ textAlign: 'center', marginTop: -5, fontSize: 15, paddingRight: 20 }} component={'div'}
                            className={' text-violet  text-center pt-14 block font-mont ' + styles.center_abs_text}>
                            <span className='text-orange '>LANCIA PUBBLICAMENTE LA  </span>
                            <br />
                            <span className='text-orange '>TUA</span>  CAMPAGNA &nbsp;
                            <span className='text-orange inline-flex'>E LE TUE </span>
                            <br />
                            NFT CARDS
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} style={{ marginTop: -10 }}>
                        <Typography style={{ fontSize: 10 }} component={'h4'} className={'!text-1xl text-violet  text-center pt-5 block font-mont'} sx={{ px: { xs: 4, md: 0 } }}>
                            &#8226;  SELEZIONA LA DURATA DI 30 O 60 GIORNI E LA TIPOLOGIA DI CAMPAGNA CHE PIU' SI ADATTA ALLE TUE ESIGENZE TRA:
                        </Typography>
                        <Typography component={'h4'} style={{ marginTop: -15, fontSize: 10 }} className={'!text-1xl text-violet italic text-center pt-5 block font-mont'} sx={{ px: { xs: 4, md: 0 } }}>
                            &#8226; "TUTTO O NIENTE": SE IL PROGETTO NON RAGGIUNGE L'OBIETTIVO PREFISSATO VENDENDO TUTTI GLI NFT, I SOSTENITORI DEL PROGETTO VENGONO RIMBORSATI
                        </Typography>
                        <Typography component={'h4'} style={{ marginTop: -15, fontSize: 10 }} className={'!text-1xl text-violet italic text-center pt-5 block font-mont'} sx={{ px: { xs: 4, md: 0 } }}>
                            &#8226;  "FLESSIBILE": SE IL PROGETTO RAGGIUNGE ALMENO IL 60<span className='font-garet'>%</span> DELL'OBIETTIVO PREFISSATO SI PROCEDE COMUNQUE CON IL SUO SVILUPPO
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className='text-center-browser'>
                <Box
                    component={'img'}
                    src='/Images/grafica_3.png'
                    sx={{
                        width: 250,
                        marginTop: 2
                    }}
                />
            </Grid>
            {/* TERZA DESCRIZIONE FINE */}
            {/* QUARTA DESCRIZIONE INIZIO */}
            <Grid item xs={12} lg={12} style={{ marginTop: 100 }} className={'text-center'}>
                <Grid container justifyContent='right' spacing={2}>
                    <Grid item xs={10} >
                        <Typography style={{ textAlign: 'center', marginTop: -120, fontSize: 15}} component={'div'} className={' text-violet pt-14 block font-mont ' + styles.center_abs_text}>
                            VENDI <span className='text-orange'>LE CARDS E</span> <br/> FINANZIA
                            <span className='text-orange'> &nbsp;IL PROGETTO</span>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Box
                            className={styles.clapperBoard2Mobile}
                            component={'img'}
                            src='/Images/clapperboard4.png'
                            sx={{
                                height: 60,
                                width: 130,
                                maxHeight: { xs: 135, md: 135 },
                                maxWidth: { xs: 320, md: 320 },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} >
                    <Grid item xs={12} style={{ marginTop: -10 }}>
                        <Typography style={{ fontSize: 10, marginTop: -20 }} component={'h4'} className={'!text-1xl text-violet  text-center pt-5 block font-mont'} sx={{ px: { xs: 4, md: 2 } }}>
                            &#8226; AL TERMINE DELLA CAMPAGNA, SE QUEST'ULTIMA HA SUCCESSO I FONDI VENGONO INVIATI AL TEAM ARTISTICO, IN CASO CONTRARIO GLI INVESTITORI VENGONO RIMBORSATI
                        </Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} className='text-center-browser'>
                <Box
                    component={'img'}
                    src='/Images/grafica_4.png'
                    sx={{
                        width: 250,
                        marginTop: 2
                    }}
                />
            </Grid>
            {/* QUARTA DESCRIZIONE FINE */}
            <Grid container className={'mt-10 !mb-10'} sx={{ pl: { xs: 0, md: 14 } }}>
                <Grid item xs={12} className={'text-center-browser'}>
                    <Typography component={'div'} className={'text-violet !text-3xl font-mont !mb-10'}>
                        PERCHE' SCEGLIERE CROWD<span className='text-orange'>MOOVI?</span>
                    </Typography>
                    {accordionBody.map((accordion, index) => {
                        return (
                            <Accordion className={'border-accordion'} style={{ marginBottom: 1 }} key={index} sx={{ width: { xs: 270, md: 650 } }}>
                                <AccordionSummary
                                    aria-controls="panel-content"
                                    id="panel-header"
                                    className='!justify-center'
                                >
                                    {accordion.domanda}
                                </AccordionSummary>
                                <AccordionDetails>
                                    {accordion.risposta}
                                </AccordionDetails>
                            </Accordion>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}