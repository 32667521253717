import { Box, Grid } from '@mui/material';
import * as React from 'react';
import '../CSS/Timeline.css';


export default function BasicTimeline() {

    return (
        // <ul class="timeline">
        //     <li className={'!text-5xl font-mont '} data-year="Q1 2023" data-text= 'SITO WEB E LANCIO "GENESIS NFT"'></li>
        //     <li className={'!text-5xl font-mont '} data-year="Q2 2023" data-text="VERSIONE ALPHA MARKETPLACE"></li>
        //     <li className={'!text-5xl font-mont '} data-year="Q3 2023" data-text="VERSIONE BETA MARKETPLACE"></li>
        //     <li className={'!text-5xl font-mont '} data-year="Q4 2023" data-text="AGGIORNAMENTO MARKETPLACE"></li>
        //     <li className={'!text-5xl font-mont '} data-year="Q1 2024" data-text="AGGIORNAMENTO SITO WEB"></li>
        // </ul>
        <Grid container>
            <Grid item xs={12}>
                <Box component={'img'} src='/Images/timeline.png' />
            </Grid>
        </Grid>
    );
}