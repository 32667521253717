import {
    SET_PAGE,
} from "./types";

export function applicationReducer(
    state = {
        page: 'home',
    },
    action
) {
    switch (action.type) {
        case SET_PAGE:
            return Object.assign({}, state, {
                page: action.page
            });
        default:
            return state;
    }
}