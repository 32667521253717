import { Grid, Typography, Box } from '@mui/material';

function Mission() {
    return (
        <Grid container className='h-full w-full' sx={{ pt: { xs: 0, md: 10 } }}>
            <Grid item xs={12}>
                <Typography component={'div'} className={'!text-7xl text-violet text-center block !tracking-tighter font-mont'} sx={{ pt: { xs: 0, md: 7 } }}>
                    LA NOSTRA <span className='text-orange inline-flex'>MISSIONE</span>
                </Typography>
            </Grid>
            <Grid item xs={12} className={' text-center-browser '}>
                <Typography component={'div'} className={'!text-xl font-garet text-violet text-center pt-10 block !tracking-tighter w-4/6'}>
                    Il nostro obiettivo è aiutare gli artisti cinematografici a finanziare i propri progetti in maniera innovativa,
                    più efficiente, creativa, coinvolgente e <span className='text-orange'>meno costosa</span>.
                    <br />
                    <br />
                    Riteniamo fondamentale portare nel mondo del cinema un più ampio <span className='text-orange'>senso di comunità</span> e libertà artistica,
                    incentivando gli artisti ad esprimere le proprie idee con imprenditoria creativa senza barriere.
                </Typography>
            </Grid>
            <Grid item xs={12} className={' text-center-browser '}>
                <Box
                    style={{ marginLeft: 1, marginTop: -150, height: 800 }}
                    component={'img'}
                    src='/Images/slogan.png' />
            </Grid>
        </Grid>
    );
}

export default Mission;
